import paycentral3dsProvider from "../3ds/providers/paycentral-3ds.js";
import stripe3ds from "../3ds/providers/stripe/stripe-3ds.js";
import utilities from "../3ds/providers/utilities/utilities.js";

/**
 * @desc Default 3D Secure provider strategy.
 */
const defaultProviderStrategy = {
    has3dsCapability: false,
    hasCurrencyCapability: (currency) => false,
    authenticate: async () => { console.log("provider does not support 3ds"); }
};

/**
 * @desc iATS 3D Secure provider strategy.
 */
const iatsProviderStrategy = {
    provider: "iats",
    has3dsCapability: true,
    hasCurrencyCapability: (currency) => (currency === "EUR" || currency === "GBP"),
    authenticate: async (card, transaction, options) => {
        //TODO-AWELLS: We can add the iats 3ds provider strategy here if we'd like.
        console.log("iats 3ds provider strategy");;
    }
};

/**
 * @desc Global Payments 3D Secure provider strategy.
 */
const globalPaymentsProviderStrategy = {
    provider: "globalpayments",
    has3dsCapability: false,
    hasCurrencyCapability: (currency) => (currency === "EUR" || currency === "GBP"),
    authenticate: async (card, transaction) => {
        console.log("paycentral 3ds provider strategy");
        return await paycentral3dsProvider.startConsumerAuthentication(card, transaction);
    },
    verifyResult: async (transaction) => {
        console.log("[verifying result]");
        return await paycentral3dsProvider.verifyResult(transaction);
    }
};

// Quick protoype triggering this all from js. It probably makes more sense to POST the whole form to the
// widget capture endpoint but we can deal with that later.
/**
 * @desc Stripe 3D Secure strategy.
 */
const stripe3DsStrategy = {
    provider: "stripe",
    has3dsCapability: false,
    hasCurrencyCapability: (currency) => true,
    authenticate:
        async (card, transaction) => {
            return await stripe3ds.handleAttemptAuthentication(card, transaction);
        },       
    verifyResult:
        async (transaction) => {
            throw Error("Stripe 3DS 'verifyResult' not implemented");
            //return await stripe3ds.handleVerifyResult(transaction);
        }
};

/**
 * @desc 3D Secure strategies.
 */
const providerTypes = {
    iats:       iatsProviderStrategy,
    imis_pay:   globalPaymentsProviderStrategy,
    payflow:    defaultProviderStrategy,
    secure_pay: defaultProviderStrategy,
    spreedly:   defaultProviderStrategy,
    stripe:     stripe3DsStrategy,
    vantiv:     defaultProviderStrategy
};

/**
 * Create Provider
 * @desc Create 3D Secure Provider by gateway type.
 * @param {{}} data
 */
export function createProvider(data) {
    try {
        const method = data.paymentRequestData.PaymentInstrument;
        const providerName = utilities.format(method.MethodData.GatewayProvider);
        const provider = providerTypes[providerName];
        if (provider) return provider;
    } catch (e) {
        return defaultProviderStrategy;
    }
    return defaultProviderStrategy;
};
