import { callApiAsync, PayCentralURL } from "../../utils/call-api.js";
import { createIframeAndPost } from "../providers/utilities/create-iframe.js";
import utilities from "../providers/utilities/utilities.js";

/**
 * @desc ACS Method URL iframe timeout length
 */
const THREEDS_METHOD_TIMEOUT = 1000;

/**
 * @desc Message to be displayed to the user in the event of an authentication failure.
 */
const UI_FAILURE_MSG = "3DS failed. Please try again or use a different credit card.";

/**
 * @desc 3DS authentication workflow options
 */
let _options = {};

/**
 * @desc ACS method url completion status (device fingerprinting)
 */
let _methodUrlComplete = "YES";

/**
 * @desc 3DS Server trans Id
 */
let _serverTransId;

/**
 * @desc The payment transaction
 */

let _transaction;


/**
 * Execute 3ds payer authentication
 * @desc Executes the api call to begin 3DS 2.0 authentication workflow with the payer
 *
 * @param {{}} card - credit card data
 * @param {{}} transaction - contains 3DS request data
 */
const startConsumerAuthentication = async (card, transaction) => {
    _transaction = transaction;
    let response;
    try {

        response = await _initialize(card, _transaction, _options);

        if (response.enrolled === "Y")
            response = await _authenticate(card, _transaction, response);

        if (response.action === "CHALLENGE")
            return _createChallengeAction(response);

    } catch (error) {
        return _createErrorAction(error);
    }
    return _createErrorAction(error);
};



/**
 * Process 1: Initialize the authentication (cardLookup, lookup, enrollmentLookup)
 * @desc Executes the api call to check 3DS enrollment and version for the payer's card
 *
 * @param {any} card - credit card data
 * @param {any} transaction - contains transaction data
 */
async function _initialize(card, transaction, options) {

    let payload = {
        GatewayId: transaction.PaymentInstrument.MethodData.GatewayId,
        Card: card,
        Total: {
            Amount: parseFloat(transaction.TransactionDetails.Total.Amount),
            Currency: {
                CurrencyCode: transaction.TransactionDetails.Total.Currency.CurrencyCode
            }
        },
        ThreeDs: {
            MerchantCountry: null,
            NotificationUrl: new PayCentralURL("3ds/notifications")
        }
    };

    const response = await callApiAsync("3ds/init", payload);

    if (response.status == 400) throw new Error(UI_FAILURE_MSG);
    if (response.status == 422) throw new Error(response.status);

    console.log("init", response, _serverTransId);

    return response.threeDs;
}


/**
 * Process 2: Execute Authentication
 * @desc Executes the api call to begin the payer authentication workflow. On authenticate completed if a
 * challenge is required the data is returned to the parent window ui so it may be displayed to the user.
 *
 * @param {{}} card - credit card data
 * @param {{}} transaction - contains 3DS request data
 */
async function _authenticate(card, transaction, threeDs) {

    _setThreeDSCodeFormValue(threeDs.threeDSServerTransactionId);

    if (threeDs.action !== "AUTHENTICATE") return threeDs;

    if (!threeDs.threeDsAction) return threeDs;

    if (threeDs.threeDsAction.methodUrl)
        await _executeDeviceFingerprinting(threeDs.threeDsAction);

    let payload = {
        GatewayId: transaction.PaymentInstrument.MethodData.GatewayId,
        Card: card,
        Total: {
            Amount: parseFloat(transaction.TransactionDetails.Total.Amount),
            Currency: {
                CurrencyCode: transaction.TransactionDetails.Total.Currency.CurrencyCode
            }
        },
        OrderTotal: {
            Amount: parseFloat(transaction.TransactionDetails.Order.OrderTotal.Amount),
            Currency: {
                CurrencyCode: transaction.TransactionDetails.Order.OrderTotal.Currency.CurrencyCode
            }
        },
        MerchantUrl: transaction.Merchant.MerchantOrigin,
        BrowserData: _getBrowserData(),
        ThreeDs: {
            BrowserData: _getBrowserData(),
            MethodUrlComplete: _methodUrlComplete,
            ThreeDSServerTransactionId: _serverTransId
        }
    };

    const response = await callApiAsync("3ds/auth", payload);

    console.log("auth", response, _serverTransId);

    return await response.threeDs;
}

/**
 * @desc Handles init authentication response from api. When a card is enrolled and a method URL is present,
 * a hidden iframe to the method URL will be created to handle device fingerprinting requirements
 *
 * @param {any} response - api call response json
 */
async function _executeDeviceFingerprinting(action, options) {
    console.log('Device fingerprint required.');

    if (!options) options = {};
    options.hide = true;
    options.timeout = typeof options.timeout === "undefined" ? THREEDS_METHOD_TIMEOUT : options.timeout;

    await createIframeAndPost(
        action.methodUrl,
        [{ name: "threeDSMethodData", value: action.methodPayload }],
        options,
    );

    console.log('DeviceFingerPrint: complete.');

    return { type: "authenticate" };
}

/**
 * Get browser data
 * @desc Retrieves client browser runtime data.
 */
function _getBrowserData() {
    let browserData = new BrowserData();
    const now = new Date();
    browserData.AcceptHeader = "application/json";
    browserData.ColorDepth = (screen.colorDepth).toString(); //screen && colorDepth(screen.colorDepth);
    browserData.JavaEnabled = (navigator && navigator.javaEnabled()).toString();
    browserData.JavascriptEnabled = "true"; // if this is running; js is enabled
    browserData.Language = (navigator && navigator.language).toString();
    browserData.ScreenHeight = (screen && screen.height).toString();
    browserData.ScreenWidth = (screen && screen.width).toString();
    browserData.Time = now.toString();
    browserData.TimezoneOffset = (now.getTimezoneOffset() / 60).toString();
    browserData.UserAgent = navigator && navigator.userAgent;
    browserData.ChallengeWindowSize = "WINDOWED_500X600";
    return browserData;
}

function _setThreeDSCodeFormValue(id) {
    _serverTransId = id;
    utilities.setTargetValue("ThreeDSCode", id);
    utilities.setTargetValue("ThreeDSGatewayTransactionId", id);
}

function _createChallengeAction(threeDs) {
    console.log('Payer challenge required.');
    return {
        id: threeDs.threeDSServerTransactionId,
        action: "CHALLENGE",
        data: threeDs.threeDsAction
    };
}

function _createErrorAction(error) {
    console.log(error.message);
    return {
        error: true,
        errorMsg: message
    };
}

//TODO-AWELLS: (work in progress) begin: add strongly typed object replacements.
class BrowserData {
    ColorDepth;        // boolean | string;
    JavaEnabled;       // boolean;
    JavascriptEnabled; // boolean;
    Language;          // boolean | string;
    ScreenHeight;      // boolean | number;
    ScreenWidth;       // boolean | number;
    Time;              // Date;
    TimezoneOffset;    // number;
    UserAgent;         // boolean | string;
}


const paycentral3dsProvider = {
    startConsumerAuthentication
};

export default paycentral3dsProvider;