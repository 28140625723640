import { PayCentralURL } from "../../../utils/call-api.js";
import utilities from "../utilities/utilities.js";

// brittle but there's no id attribute so I'm just gonna make it work for now.
const form = document.forms[0];

/**
 * @desc 3DS Server trans Id
 */
let _transactionIntentId;

const handleAttemptAuthentication = async (card, transaction) => {

    console.log("POST widget/creditcard/checkout");

    let createIntentUrl = new PayCentralURL("widget/creditcard/checkout");
    createIntentUrl += "&json=true";

    let response = await fetch(createIntentUrl, { method: 'POST', body: new FormData(form) });
    const data = await response.json();

    if (data.error) {
        console.warn("checkout error!", data);
        return data;
    } else {
        utilities.setTargetValue("TransactionIntentId", data.id);
        //utilities.setTargetValue("gatewayTransactionIdr", data.status.gatewayTransactionIdentifier); //console.log("gatewayTransactionIdentifier:", data.status.gatewayTransactionIdentifier);
        form.action = new PayCentralURL("widget/creditcard/checkout");
    }

    if (data.status.nextAction.type === "ThreeDsChallenge" || data.status.nextAction.type === 1) {
        return {
            action: "CHALLENGE",
            gateway: "stripe",
            stripe: true,
            data: data.status.nextAction.genericAction.stripe_js,
            transactionIntentId: data.id,
            gatewayTransactionId: data.status.gatewayTransactionIdentifier,
            nextAction: "ThreeDsChallenge"
        };
    } else {
        return {
            action: "COMPLETE"
        }
    }
    
};


function hasTransactionIntentId() {
    var target = utilities.getElementById("TransactionIntentId");
    var id = target.value;

    if (!id || utilities.isEmptyOrSpace(id)) {
        return false;
    } else {
        _transactionIntentId = id;
        return true;
    }
}

const stripe3DS = {
    handleAttemptAuthentication
};

export default stripe3DS;
