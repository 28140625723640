// Contains flags that can be used to invoke features to assist in debugging.
// Flags should not be used to alter how the software behaves, but can be used to trigger features like verbose debugging.
// Enumerate all known flags here with a default value (typically feature disabled).
// Consumer may then modify the flags object directly using paycentral.flags to add/remove/modify properties.
const flags = {

    logFrameSrc: false,                 // log to console the iframe url when the paymentcontrol is loaded using PaymentRequest.show()
    logPrimaryPayload: false,           // log to console the primary payload posted to the paymentcontrol when calling PaymentRequest.submit()
    loadTimeout: undefined,             // override the time the SDK will wait for the payment frame to load
    submitTimeout: undefined,           // override the time the SDK will wait for a response after submitting the payment
    verboseLogging: true,               // enable verbose logging
    showOnError: true,                  // show the frame even if an error occurs (only used if PayCentral.ShowOptions.showOnError is not supplied)
    includeAiopidInErrors: true,        // include AppInsights operation id in error messages

    // for test
    test_forceInvalidFrameSrc: false,        // force the frame src to be invalid

};


export { flags };