"use strict";

import * as errorHandler from "paycentral.sdk.error";
import * as paymentRequestFactory from "paycentral.sdk.paymentrequest";
import * as environment from "paycentral.sdk.environment";
import * as logger from "paycentral.sdk.logger";

export function create(token: string, paycentralUiUrl: string): PayCentral.Internal.PayCentralInstance {

    return (new PayCentralInstance(token, paycentralUiUrl)).api();

}

class PayCentralInstance {

    token;
    paycentralUiUrl;
    paymentRequest: PayCentral.Internal.PaymentRequest = null;
    destroyed = false;

    constructor(token: string, paycentralUiUrl: string) {

        if (!(typeof token === "string" && token)) throw errorHandler.toPayCentralError("Expected token to be a non-empty string.");
        if (!(typeof paycentralUiUrl === "string" && paycentralUiUrl)) throw errorHandler.toPayCentralError("Expected paycentralUiUrl to be a non-empty string.");

        this.token = token;
        this.paycentralUiUrl = paycentralUiUrl;
    }

    /*
     * Return an api to this instance
     */
    api(): PayCentral.Internal.PayCentralInstance {
        const me = this;
        return {

            destroy: () => me.destroy(),
            get token() { return me.token },
            get paycentralUiUrl() { return me.paycentralUiUrl },
            get origin() { return environment.origin },
            get active() { return me.isActive() },
            get complete() { return me.isComplete() },

            // the public property contains all the properties exposed to an sdk consumer
            public: {
                createPaymentRequest: (method: PayCentral.PaymentMethod,
                    options: PayCentral.PaymentRequestOptions) => me.createPaymentRequest(method, options),
                destroy: () => me.destroy()
            }
        };
    }

    createPaymentRequest(method: PayCentral.PaymentMethod,
        options: PayCentral.PaymentRequestOptions): PayCentral.PaymentRequest {

        logger.verboseinfo("paycentral.sdk.instance.createPaymentRequest() called.", this.getLogProperties());

        this.throwIfDestroyed();
        if (this.paymentRequest) {
            if (!this.paymentRequest.destroyed)
                throw errorHandler.toPayCentralError(
                    "There is an existing PaymentRequest associated with the PayCentral instance. To create a new PaymentRequest, destroy() the existing one first.");
            // Disallow multiple payments using the same authtoken
            if (this.paymentRequest.complete)
                throw errorHandler.toPayCentralError(
                    "The PaymentRequest created from this PayCentral instance has completed. To create a new PaymentRequest, create a new instance of PayCentral with a new token.");
        }
        this.paymentRequest = paymentRequestFactory.create(this.api(), method, options);
        return this.paymentRequest.public;
    }

    destroy() {
        logger.verboseinfo("paycentral.sdk.instance.destroy() called.", this.getLogProperties());
        if (this.destroyed) return;
        if (this.paymentRequest) this.paymentRequest.destroy();
        this.destroyed = true;
    }

    throwIfDestroyed() {
        if (this.destroyed)
            throw errorHandler.toPayCentralError("This PayCentral instance has been destroyed and can no longer be acted on.");
    }

    isActive() {
        // We consider this PayCentral instance active if 
        //   It hasn't been destroyed 
        //     AND
        //   The payment request associated with it has not completed
        return !this.destroyed && !this.paymentRequest?.complete;
    }

    isComplete() {
        return this.paymentRequest?.complete ?? false;
    }

    getLogProperties() {
        return { token: this.token };
    }

}
