/**
 * Call PayCentral API async
 * @desc Send json request tp PayCentral UI API.
 *
 * @param {any} endpoint
 * @param {any} data
 * @param {any} verb
 */
export async function callApiAsync(endpoint, data, verb = "POST") {
    console.log(verb, `'/${endpoint}'`, data);
    const url = PayCentralURL(endpoint);
    const options = {
        method: verb,
        body: JSON.stringify(data),
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json"
        }
    };
    // fetch from api.
    const response = await fetch(url, options);

    return await response.clone().json();
}

//TODO-AWELLS: Make this a class or maybe even extend the native URL class?
export function PayCentralURL(endpoint) {
    // sanitize input (trim any leading or trailing slashes).
    endpoint = endpoint.replace(/(^\/+|\/+$)/gi, '');
    const uilocation = new URL(window.location);

    var path;
    if (uilocation.pathname.includes("capture"))
        path = uilocation.pathname.replace(/(widget\/creditcard\/capture)/i, endpoint);
    else
        path = uilocation.pathname.replace(/(widget\/creditcard)/i, endpoint);

    let url = new URL(path, uilocation.origin);
    url.searchParams.set('token', uilocation.searchParams.get('token'));
    return url;
}