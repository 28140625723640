type CustomEventHandler = (event: CustomEvent) => void;

let eventTarget: EventTarget = new EventTarget();

interface PaymentRequestEvents {
    on: (event: string, handler: CustomEventHandler) => void;
    emit: (event: string, detail?: any) => void;
}

const events: PaymentRequestEvents = {
    on: (event: string, handler: CustomEventHandler) => {
        const wrappedHandler = (e: Event) => handler(e as CustomEvent);
        eventTarget.addEventListener(event, wrappedHandler as EventListener);
    },
    emit: (event: string, detail?: any) => {
        const customEvent = new CustomEvent(event, { detail });
        eventTarget.dispatchEvent(customEvent);
    }
};

export default events;