"use strict";

import * as utilities from "paycentral.sdk.utilities";
import * as errorHandler from "paycentral.sdk.error";

/*
 * Scripts that must be present in the page for iATS 3DS challenge to work
 */
export const externalScripts = [
    "https://www.uk.iatspayments.com/netgate/iats3ds.min.js?v1.1",                      //increment version number when notified of sdk update.
    "https://hosted.paysafe.com/threedsecure/js/latest/paysafe.threedsecure.min.js"
];

export default function create(message: PayCentral.Internal.InboundPaymentMessage): PayCentral.Internal.ThreeDsChallengeHandler {
    return new ChallengeHandler(message);
}

class ChallengeHandler {

    message: PayCentral.Internal.InboundPaymentMessage;

    constructor(message: PayCentral.Internal.InboundPaymentMessage) {
        this.message = message;
    }

    async challenge(): Promise<PayCentral.Internal.ThreeDsChallengeResult> {

        if (!window["iats3ds"]) {
            throw errorHandler.toPayCentralError(`The iATS 3DS script is not available. The 3DS challenge cannot be handled.`);
        }

        try {

            const response: { success: boolean, authId?: string, errorMessage?: string } =
                await window["iats3ds"].getChallenge(this.message.json.clientID, this.message.json.sdkChallengePayload);

            console.log("response", JSON.stringify(response));

            if (response.success === true && response.authId) {
                return {
                    succeeded: true,
                    action: "Confirm",
                    threeDSCode: response.authId
                };
            }

            let errors = !!response.errorMessage
                ? [utilities.stringify(response.errorMessage)]
                : ["3DS failed for an unknown reason"];

            return {
                succeeded: false,
                errors: errors
            };

        } catch (e) {
            throw errorHandler.toPayCentralError(`iATS 3DS failed unexpectedly with error: ${utilities.stringify(e)}`);
        }

    }

    get providerName() {
        return "iATS";
    }

}
