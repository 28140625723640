import * as logger from "paycentral.sdk.logger";
import * as abortable from "paycentral.sdk.abortable";

"use strict";

const scriptLoadTimeout = 10000;
const loaded: string[] = [];

/*
 * Promises to add one or more script elements to the document.
 * 
 * @returns AbortablePromise that will resolve once all the scripts have been processed.
 *                   succeeded: indicates if all the scripts loaded successfully
 *                   errors: contains details of scrips that did not load
 *
 */
export function loadScriptsAbortablePromise(scripts: string[]): PayCentral.Internal.AbortablePromise<{ succeeded: boolean, errors?: string[] }> {

    let _resolve, _reject;
    let settled = false;

    const toAdd = (scripts ?? []).filter(x => !isScriptAlreadyLoaded(x));
    if (toAdd.length === 0) {
        return abortable.AbortableResolvedPromise({ succeeded: true });
    }

    // setup the primary promise returned by this function
    // we resolve or reject it further on
    const promise: Promise<{ succeeded: boolean, errors?: string[] }> = new Promise((resolve, reject) => {
        _resolve = resolve;
        _reject = reject;
    });

    // get promises for all the scripts
    const scriptPromises = toAdd.map((src) => loadScriptAbortablePromise(src));

    // setup a single promise that will resolve when all the scripts have either loaded or rejected
    // @ts-ignore    Babel will transpile Promise.allSettled
    Promise.allSettled(scriptPromises.map((x) => x.promise))
        .then((results) => {
            if (settled) return;        // must have aborted
            settled = true;
            const failed = (results.filter(x => x.status === "rejected") ?? []) as PromiseRejectedResult[];
            if (failed.length === 0) {
                _resolve({ succeeded: true });
            } else {
                _resolve({
                    succeeded: false,
                    errors: failed.map(x => x.reason.error)
                });
            }
        });

    // setup an aborter that will abort each of the individual script promises
    const aborter = (reason?: string) => {
        if (settled) return;        // either already aborted OR allSettled ran to completion
        settled = true;
        for (const s of scriptPromises) {
            s.abort(reason);
        }
        // reject the primary promise with the aborter reason
        _reject(reason);
    }

    return abortable.AbortablePromise(promise, aborter);

}

/*
 * Tests if a script with the supplied src is already present on the page. 
 * Very simplistic implementation that just looks for an existing script with the same src.
 * Will not work with relative scripts or where the script path is the same but parameters are different.
 * 
 * @returns boolean
 *
 */
function isScriptAlreadyLoaded(src: string) {

    const scripts = Array.from(document.scripts) || [];

    return loaded.some(l => l === src)
        || scripts.some(script => script.src && script.src.toLowerCase() === src.toLowerCase());
}

/*
 * Promises to add a script element to the document.
 * 
 * @returns AbortablePromise that will resolve if the script successfully loads and reject with a reason if it does not.
 
 */
function loadScriptAbortablePromise(src: string, async = true, type = "text/javascript"): PayCentral.Internal.AbortablePromise<{ succeeded: boolean, src: string, error?: string }> {

    let aborter: (reason: string) => void;

    const promise: Promise<{ succeeded: boolean, src: string, error?: string }> = new Promise((resolve, reject) => {

        let script,
            timer,
            status = "pending";

        try {
            script = document.createElement("script");
            script.type = type;
            script.async = async;
            script.src = src;

            script.addEventListener("load", (ev) => {
                if (status !== "pending") return;
                logger.info(`Successfully loaded script ${src}`);
                if (timer) clearTimeout(timer);
                loaded.push(src);
                status = "resolved";
                resolve({
                    succeeded: true,
                    src: src
                });
            });

            script.addEventListener("error", (ev) => {
                if (status !== "pending") return;
                logger.error(`Error loading script ${src}`);
                if (timer) clearTimeout(timer);
                script.remove();
                status = "rejected";
                reject({
                    succeeded: false,
                    src: src,
                    error: `Failed to load the script ${src}`
                });
            });

            timer = setTimeout(() => {
                if (status !== "pending") return;
                logger.error(`Timed out loading script ${src}`);
                script.remove();
                status = "rejected";
                reject({
                    succeeded: false,
                    src: src,
                    error: `Timed out loading script ${src}`
                });
            },
                scriptLoadTimeout);

            aborter = (reason?: string) => {
                if (status !== "pending") return;
                logger.info(`Aborting load of script ${src}`);
                if (timer) clearTimeout(timer);
                script.remove();
                status = "rejected";
                reject({
                    succeeded: false,
                    src: src,
                    error: reason
                });
            }

            document.body.appendChild(script);

        } catch (error) {
            if (script) script.remove();
            if (timer) clearTimeout(timer);
            status = "rejected";
            reject({
                succeeded: false,
                src: src,
                error: `Error adding script ${src}`
            });
        }
    });

    return abortable.AbortablePromise(promise, aborter);

};
