import utilities from "../utilities/utilities.js";

/**
 * @desc Generates a random number to identify the iframe element
 */
const randomId = Math.random().toString(16).substr(2, 8);

/**
 * @desc callback function for fingerprinting promise resolve
 */
let on3dsFrameResolvedFn;

/**
 * desc add 3ds iframe listeners.
 *
 * @param {any} iframe
 * @param {any} callback
 * @param {any} container
 */
function addIframeListener(iframe, callback, container) {
    iframe.onload = function () {
        if (callback && typeof callback === "function") {
            callback(iframe.contentWindow, container);
        }
    };
};

/**
 * desc Add notification window message listeners
 *
 * @param {any} timeout
 */
function addNotificationListener(timeout) {
    window.addEventListener("message", (e) => {
        const eventData = e.data;

        // Discard invalid events.
        if (!eventData.hasOwnProperty("event") || !eventData.hasOwnProperty("data")) return;

        // Notification data.
        if (eventData.data.hasOwnProperty("statusIndicator")) {
            utilities.setMethodStatusIndicator(eventData.data.statusIndicator);
            on3dsFrameResolvedFn("Y");
        }

        // Remove the iframe and clear the timeout.
        ensureIframeClosed(timeout);
    });
}

/**
 * @desc Remove the dynamically create iframe and clear the associated timeout.
 *
 * @param {any} timeout
 */
function ensureIframeClosed(timeout) {
    if (timeout) {
        clearTimeout(timeout);
    }

    try {
        Array.prototype.slice.call(document.querySelectorAll(`[target$="-${randomId}"],[id$="-${randomId}"]`))
            .forEach((element) => {
                if (element.parentNode) {
                    element.parentNode.removeChild(element);
                }
            });
    } catch (e) {
        /** nom, nom, nom */
    }
}

/**
 * @desc Inserts a hidden iframe into the checkout page so that the browser information can be collected
 * by the ACS using the Method URL
 *
 * @param {any} endpoint
 * @param {any} fields
 * @param {any} options
 */
export const createIframeAndPost = async (endpoint, fields, options) => {

    const resolveData = { threeDSCompInd: "U" };
    let frameTimeout;

    /**
     * Create iframe, append to document, and submit form
     */
    const create3dsIframe = new Promise(function (resolve) {

        if (!endpoint || !fields) resolve(resolveData);
        addNotificationListener();

        const createIframeAndSubmitForm = async () => {

            // create iframe, append to document, and submit form
            const iframe = document.createElement("iframe");
            iframe.id = iframe.name = `PayCentral-3DS-DeviceFingerprinting-${randomId}`;
            iframe.style.display = options.hide ? "none" : "inherit";
            addIframeListener(iframe);

            // appends and submits a form in the created iframe
            const form = utilities.createForm(endpoint, iframe.id, fields);
            iframe.appendChild(form);
            form.submit();

            const onFrameNotificationComplete = new Promise(function (resolve) {
                on3dsFrameResolvedFn = resolve;
            });
            resolveData.threeDSCompInd = await onFrameNotificationComplete;

            return randomId;
        };

        // execute
        createIframeAndSubmitForm().then((iframeId) => {
            ensureIframeClosed(frameTimeout);
            resolveData.iframe = iframeId;
            resolve(resolveData); // resolve createMethodFrame Promise
        });
    });

    /**
     * Method URL iframe timeout.
     */
    const promiseTimeout = new Promise((resolve) => {
        frameTimeout = setTimeout(() => {
            resolveData.threeDSCompInd = "N";
            resolve(resolveData); // resolve promiseTimeout Promise
        }, options.timeout);
    });

    /**
     * Promise.race settles when the first one of these promises settles(one is a timeout based promise the other is the createIframe promise).
     * It resolves / rejects with the value received from the settled promise
     */
    const data = Promise.race([create3dsIframe, promiseTimeout]);
    return data;
};