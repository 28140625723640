"use strict";

import * as utilities from "paycentral.sdk.utilities";
import * as errorHandler from "paycentral.sdk.error";
import * as provider from "../Views/scripts/3ds/main";      

export default function create(message: PayCentral.Internal.InboundPaymentMessage): PayCentral.Internal.ThreeDsChallengeHandler {
    return new ChallengeHandler(message);
}

class ChallengeHandler {

    message: PayCentral.Internal.InboundPaymentMessage;

    constructor(message: PayCentral.Internal.InboundPaymentMessage) {
        this.message = message;
    }

    async challenge(): Promise<PayCentral.Internal.ThreeDsChallengeResult> {

        let pResolve, pReject;

        const promise: Promise<PayCentral.Internal.ThreeDsChallengeResult> = new Promise((resolve, reject) => {
            pResolve = resolve;
            pReject = reject;
        });

        try {
            await provider.display3dsChallenge(this.message.json, success, error);
        } catch (e) {
            throw errorHandler.toPayCentralError(`3DS failed unexpectedly with error: ${utilities.stringify(e)}`);;
        }

        return promise;

        function success(threeDSCode): void {
            pResolve({
                succeeded: true,
                threeDSCode: threeDSCode
            });
        }

        function error(e): void {
            let errors: string[];
            if (Array.isArray(e))
                errors = e.filter((x) => x !== null && x !== undefined)
                    .map((x) => utilities.stringify(x));
            else if (e !== null && e !== undefined)
                errors = [utilities.stringify(e)];

            if (!errors?.length) errors = ["3DS failed for an unknown reason"];

            pResolve(
                {
                    succeeded: false,
                    errors: errors
                });
        }

    }

    get providerName() {
        return "PayCentral";
    }

}
