import { PaymentRequestData } from "../../core/models";
import * as component from "./component";
import * as http from "../../core/http";
import events from "../../core/events";

interface UiActions {
    collectPaymentData: (paymentRequest: PaymentRequestData) => PaymentRequestData;
    show: (paymentRequest: PaymentRequestData) => Promise<void>;
}

const requiredScripts = ["https://js.stripe.com/v3/"];

async function handleAction(action: any) {
    try {
        switch (action.type) {
        case "render_component":
            return await component.render(action.details);
        case "tokenize":
            return await component.tokenize(action.details);
        default:
            throw new Error("Could not establish an appropriate action handler type.");
        }
    } catch (error) {
        console.error("HandleAction Error: ", error);
        throw error;
    }
}

const ui: UiActions = {
    collectPaymentData: (paymentRequest: PaymentRequestData) => {
        if (!paymentRequest.paymentMethod.details?.token) {
            paymentRequest.requiresAction = true;

            const action = {
                type: "tokenize",
                provider: "stripe",
                details: component.getTokenizationActionDetails(paymentRequest)
            };

            // emit sdk action event.
            events.emit("sdk.action", { type: "response", action });
        }

        return paymentRequest;
    },

    show: async (paymentRequest: PaymentRequestData) => {
        if (paymentRequest.paymentMethod.paymentType !== "DirectDebit") {
            throw new Error("Payment method component type not implemented");
        }

        const gateway = await http.get(`payments/gateways/${paymentRequest.paymentMethod.gateway.provider}/${paymentRequest.paymentMethod.gateway.id}`);
        const action = {
            type: "render_component",
            provider: "stripe",
            details: component.getRenderComponentActionDetails(paymentRequest, gateway)
        };

        // emit sdk action event.
        events.emit("sdk.action", { type: "action", action });
    }
};

export { ui, requiredScripts, handleAction };