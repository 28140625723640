"use strict";

import * as errorHandler from "paycentral.sdk.error";
import * as stripe from "../scripts/app/providers/stripe/actions";

export function getProvider(action): PayCentral.Internal.ActionHandler | undefined {

    if (/stripe/i.test(action.provider))
        return stripe;

    //throw errorHandler.toPayCentralError("Could not establish an appropriate action handler.");
}
