"use strict";

import * as logger from "paycentral.sdk.logger";
import * as utilities from "paycentral.sdk.utilities";
import * as abortable from "paycentral.sdk.abortable";
import { flags } from "paycentral.sdk.flags";

const unknownError = "Unknown error";

export function toPayCentralError(error: any, options?: PayCentral.PayCentralErrorOptions): PayCentral.PayCentralError {
    let wrapped: PayCentral.PayCentralError;
    if (error instanceof PayCentralError)
        wrapped = error as PayCentral.PayCentralError;
    else if (error instanceof abortable.AbortedOperationSignal) {
        const signal = error as PayCentral.Internal.AbortedOperationSignal;
        wrapped = new PayCentralError(signal.reason);
        wrapped.options = { fromAbortedOperation: true };
        wrapped.cause = signal;
    }
    else if (error instanceof Error) {
        wrapped = new PayCentralError(error.message);
        wrapped.cause = error;
    } else if (nothingUseful(error)) {
        wrapped = new PayCentralError(unknownError);
    }
    else {
        let message: string;
        if (Array.isArray(error))
            message = combineErrorMessages(error);
        else
            message = utilities.stringify(error);
        wrapped = new PayCentralError(message);
    }
    if (options) {
        wrapped.options = { ...(wrapped.options ?? {}), ...options };
    }
    addAppInsightsOperationIdToError(wrapped);
    wrapped.log();
    return wrapped;
}

function nothingUseful(error: any): boolean {
    if (error === null || typeof error === "undefined") return true;
    if (Number.isNaN(error)) return true;
    if (typeof error === "string" && (error.length === 0 || /^\s*$/.test(error))) return true;
    return false;
}

function combineErrorMessages(errors: any[]) {
    const refined = errors.filter(e => !nothingUseful(e)).map(e => utilities.stringify(e).trim()).join("\r\n");
    return refined ? refined : unknownError;
}

export function PayCentralError(this: PayCentral.PayCentralError, message, name?: string) {
    this.name = name ?? 'PayCentral.Error';
    this.message = message;
    this.stack = (new Error()).stack;
    this.logged = false;
    this.log = () => {
        if (!this.logged) {
            if (this.options?.logAsInfo || this.options?.fromAbortedOperation)
                logger.info(this);
            else
                logger.error(this);
            this.logged = true;
        }
    };
}

export function error_ComponentUnresponsive() {
    return new PayCentralError("The payment control is not responding.", "PayCentral.ComponentUnresponsive");
}

function addAppInsightsOperationIdToError(error: PayCentral.PayCentralError) {
    if (!flags.includeAiopidInErrors) return;
    if (error.appInsightsOperationId) return;
    const aiopid = logger.appInsightsOperationId();
    if (!aiopid) return;
    error.appInsightsOperationId = aiopid;
    error.message = `${error.message}\n(aiopid: ${aiopid})`;
}

PayCentralError.prototype = new Error;