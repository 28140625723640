"use strict";

import * as errorHandler from "paycentral.sdk.error";
import paycentralHandler from "paycentral.sdk.3ds.paycentral";
import iatsHandler from "paycentral.sdk.3ds.iats";
import stripeHandler from "paycentral.sdk.3ds.stripe";
import * as iats from "paycentral.sdk.3ds.iats";

/*
 * Get the external 3ds scripts that must be loaded for a given PaymentMethod
 */
export function getExternalScripts(paymentMethod: PayCentral.PaymentMethod) {

    if (/iats/i.test(paymentMethod.gateway.provider))
        return iats.externalScripts;

    return [];

}

export function getChallengeHandler(message: PayCentral.Internal.InboundPaymentMessage) : PayCentral.Internal.ThreeDsChallengeHandler {

    console.log("current action:", "SDK DISPLAY CHALLENGE"); //TODO: REMOVE ME

    if (message.json) {
        const keys = Object.getOwnPropertyNames(message.json);
        if (keys.some(x => x === "clientID") && keys.some(x => x === "sdkChallengePayload")) {
            // looks like iATS
            return iatsHandler(message);
        }
    }

    if (message.json) {
        const keys = Object.getOwnPropertyNames(message.json);
        if (keys.some(x => x === "stripe")) {
            return stripeHandler(message);
        }
    }

    if (message.json?.data) {
        const keys = Object.getOwnPropertyNames(message.json.data);
        if (keys.some(x => x === "challengePayload")) {
            return paycentralHandler(message);
        }
    }

    throw errorHandler.toPayCentralError("Could not establish an appropriate 3DS handler.");
}