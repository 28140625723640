/*
 * Exposes environmental attributes the SDK depends on.
 * Exposing via a module allows this to be mocked for testing.
 */

// Set this to true to disable the SDK
const disabled = false;

const features = {
    promise: "Promise" in window,
    currentScript: "currentScript" in document
};

const sdkUrl = (document.currentScript as HTMLScriptElement)?.src;
const paycentralUiUrl = getPayCentralUiUrl((document.currentScript as HTMLScriptElement)?.src);

const origin = window?.location?.origin;

function getPayCentralUiUrl(scriptSrc: string): string {

    // Establish the PayCentralUiUrl that the payment widget URL will be calculated from.
    // We base this on the url we loaded this script from.
    // Take everything before the known script sub-path and consider that to be the PayCentralUiUrl.
    // This may contain a region such as "ap" e.g. https://paycentral-ui.imis.com/ap/js/sdk/paycentral.js
    // With a script sub-path of /js/sdk the PayCentralUiUrl would be calculated as https://paycentral-ui.imis.com/ap/

    const scriptSubPath = "/js/sdk";

    if (!(typeof scriptSrc === "string" && scriptSrc)) return null;

    const escapedScriptSubPath = scriptSubPath.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const pathRegExp = new RegExp(`^(.*)${escapedScriptSubPath}\/[^\/]*$`);
    const matches = scriptSrc.match(pathRegExp);
    return matches ? matches[1] : null;

}

export { disabled, features, origin, paycentralUiUrl, getPayCentralUiUrl, sdkUrl };