/**
 * Create form.
 * @desc Generic function for creating a form element
 *
 * @param {String} action - the action for the form element
 * @param {String} target - the target (iframe name) for the form element
 * @param {String} fields - the input fields we will add to the form element
 */
const createForm = (action, target, fields) => {
    const form = document.createElement("form");
    form.setAttribute("method", "POST");
    form.setAttribute("action", action);
    form.setAttribute("target", target);

    for (const field of fields) {
        const input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", field.name);
        input.setAttribute("value", field.value);
        form.appendChild(input);
    }
    return form;
}

/**
 * Format gateway name.
 * @desc Standardize gateway name formatting.
 * Note: We don't need this, but it allowed me to ignore the gateway name format (to some degree)
 * @param {String} str
 */
function format(str) {
    return str.replace(" ", "_")
        .replace("(", "")
        .replace(")", "") // necessary
        .toLowerCase();
};

/**
 * Set Method Status Indicator
 * @desc Set ACS Method URL completions status value
 *
 * @param {String} status
 */
const setMethodStatusIndicator = (status) => {
    let element = document.getElementById("MethodUrlComplete");
    if (element) {
        element.value = status;
    }
}

/**
 * Get Method Url Complete Value
 * @desc Get ACS Method URL completions status value
 */
const getMethodUrlCompleteValue = () => {
    let element = document.getElementById("MethodUrlComplete");
    if (element) {
        return element.value;
    }
    return "U";
}

/**
 * Set element value
 * @desc Queries the document for the targeted element id then sets its value
 *
 * @param {String} status
 */
const setTargetValue = (id, value) => {
    const element = document.getElementById(id);
    if (element && value) {
        element.value = value;
        return true;
    } else {
        //console.log("target element not found.", id);
        return false;
    }
}

/**
 * Get element
 * @desc Queries the document for the targeted element id then sets its value
 *
 * @param {String} status
 */
const getElementById = (id) => {
    let element = document.getElementById(id);
    if (element) {
        return element;
    }
    throw Error(id + "not found.");
}

/**
 * Get element value
 * @desc Queries the document for the targeted element id then sets its value
 *
 * @param {String} status
 */
const getElementValue = (id) => {
    let element = document.getElementById(id);
    if (element) {
        return element.value;
    }
    return null;
}

/**
 * Check for empty and whitespace.
 * @desc detects null, empty or all-spaces only.
 *
 * @param {any} str - a string value
 */
const isEmptyOrSpace = (str) => {
    return str === null || str.match(/^ *$/) !== null;
}

/**
 * Convert to bool.
 * @desc Convert value to bool.
 *
 * @param {any} s - any value
 */
const convertToBool = (s) => {
    if (typeof s == "boolean") return s;
    if (typeof s != "string") return undefined;
    if (s.toLowerCase() === "true") return true;
    if (s.toLowerCase() === "false") return false;
    return undefined;
}

const utilities = {
    createForm,
    format,
    getMethodUrlCompleteValue,
    setMethodStatusIndicator,
    setTargetValue,
    getElementById,
    getElementValue,
    isEmptyOrSpace,
    convertToBool
};

export default utilities;