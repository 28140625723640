// IE11 will require polyfills for the following
//      Promise
//      document.currentScript

// TODO JB This includes all relevant changes to paycentral.js up to and including e085324f7daab26d5ba56608fe0d18e989a3a0d2

/// <reference path="./paycentral.sdk.definition.internal.d.ts" />
/// <reference path="./paycentral.sdk.definition.public.d.ts" />

"use strict";

import * as logger from "paycentral.sdk.logger";
import * as errorHandler from "paycentral.sdk.error";
import * as instanceFactory from "paycentral.sdk.instance";
import * as environment from "paycentral.sdk.environment";
import * as messaging from "paycentral.sdk.messaging";
import { flags } from "paycentral.sdk.flags";

let initialized = false;

let instances: PayCentral.Internal.PayCentralInstance[];
let available: boolean;
let paycentralUiUrl: string;
let errors: string[];

const paycentral = proxy();

/*
 * Construct a {@link PayCentral.Api} which will lazily initialize the SDK when it is first accessed.
 *
 * @returns A {@link PayCentral.PayCentral}
 *
 */
function proxy(): PayCentral.Api {

    const proxy = (token: string) => { return initializeCheckAvailableThen(() => create(token)) };
    proxy.create = (token: string) => { return initializeCheckAvailableThen(() => create(token)) };
    proxy.destroy = () => { initializeCheckAvailableThen(() => destroy()) };
    Object.defineProperty(proxy, "available", { get() { return initializeThen(() => available) } });
    Object.defineProperty(proxy, "errors", { get() { return initializeThen(() => errors) } });
    Object.defineProperty(proxy, "flags", { get() { return flags } });

    // @ts-ignore  
    if (!!UNIT_TEST) {
        // Only available in test.
        // UNIT_TEST is defined in webpack config.
        proxy.__initialize = () => initialize();
    }

    // @ts-ignore   
    // tsc reports a type error because it does not see the "available" property which was added with Object.defineProperty
    return proxy as PayCentral.Api;

}

/*
 * Initialize the SDK (if not already), then call a delegate.
 *
 * @returns the return value of the delegate
 *
 */
function initializeThen(func) {
    if (!initialized) initialize();
    return func();
}

/*
 * Initialize the SDK (if not already), check it is available, then call a delegate.
 * If not available then throw an Error.
 *
 * @returns the return value of the delegate
 *
 * @throws {@link PayCentral.PayCentralError}
 * Thrown if the SDK is not available.
 *
 */
function initializeCheckAvailableThen(func) {
    if (!initialized) initialize();
    throwIfUnavailable();
    return func();
}

/*
 * Throw an Error is the SDK is not available for any reason.
 *
 *
 * @throws {@link PayCentral.PayCentralError}
 * Thrown if the SDK is not available.
 *
 */
function throwIfUnavailable() {
    if (available) return;
    throw errorHandler.toPayCentralError("PayCentral is unavailable.");
}

/*
 * Initialize the SDK.
 *
 * @privateRemarks
 * When consumed from iMIS or by a third party this will only be called once.
 * Under automated test this may be called multiple times to effectively re-initialize the module for each test scenario.
 *
 */
function initialize() {

    instances = [];
    initialized = true;
    available = false;
    errors = [];
    paycentralUiUrl = null;

    if (!!environment.disabled) {
        errors.push("PayCentral is disabled.");
        return;
    }

    if (!isCompatibleClient()) return;

    paycentralUiUrl = environment.paycentralUiUrl;

    if (!shouldBeAvailable()) return;

    messaging.initialize();

    available = true;

}

/*
 * Detect incompatibilities with the client that would result in the SDK functioning incorrectly.
 *
 * @remarks
 * Populates the errors array with detailed of any problems detected.
 *
 * @returns true to indicate the client is compatible, else false
 *
 */
function isCompatibleClient() {

    // Feature detection
    // IE11 will require some polyfills
    if (!environment.features.promise)
        errors.push(
            "This browser does not support Promise. If you are providing a polyfill, ensure it is loaded before PayCentral.");
    if (!environment.features.currentScript)
        errors.push(
            "This browser does not support document.currentScript. If you are providing a polyfill, ensure it is loaded before PayCentral.");

    if (!errors.length) return true;

    for (let error of errors) {
        logger.warn(error);
    }
    logger.unavailable();

    return false;

}

/*
 * Detect other reasons that would result in the SDK being unavailable or non functional.
 *
 * @remarks
 * Populates the errors array with detailed of any problems detected.
 *
 * @returns true to indicate that are no other issues, else false
 *
 */
function shouldBeAvailable() {

    if (!paycentralUiUrl)
        errors.push("Unable to establish the PayCentral URL.");

    if (!errors.length) return true;

    for (let error of errors) {
        logger.warn(error);
    }
    logger.unavailable();

    return false;

}

/*
 * Create a consumable instance of the SDK by which its functionality can be accessed.
 *
 * @returns {@link PayCentral.PayCentral}
 *
 * @throws {@link PayCentral.PayCentral.PayCentralError}
 * Thrown if there is any reason that an instance cannot be constructed.
 */
function create(token: string): PayCentral.PayCentral {

    logger.verboseinfo("paycentral.sdk.create() called.", { token: token });

    // Only allow one active PayCentral instance.
    // Technically this is not necessary. We absolutely could have multiple exist on a single page, each with their own payment controls.
    // If we want to allow that in the future, just remove the restriction here.
    // There would be considerations about handling 3DS if there were multiple active instances.
    // The 3DS challenge window would probably not know which payment frame to post back to.

    if (instances.some((x) => x.active))
        throw errorHandler.toPayCentralError(
            "There is an existing active instance of PayCentral. To create a new instance, destroy() the existing one first.");

    // Make sure the token is unique
    // We allow the token to be used against a new instance provided there is no other active or completed instance with the same token.
    if (instances.some((x) => x.token === token && (x.active || x.complete)))
        throw errorHandler.toPayCentralError(
            "The supplied token has already been used for another PayCentral instance. Each instance requires a new token.");

    const instance = instanceFactory.create(token, paycentralUiUrl);
    instances.push(instance);

    return instance.public;
}

/*
 * Destroy every PayCentral instance that has been created.
 *
 * @remarks
 * This will tear down any UI that was created by any of the instances.
 */
function destroy() {
    logger.verboseinfo("paycentral.sdk.destroy() called.");
    instances.forEach((x) => x.destroy());
}

export default paycentral;