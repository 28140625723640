"use strict";

import { flags } from "paycentral.sdk.flags";

declare var appInsights: any;
const logPrefix = "PayCentral: ";

enum SeverityLevel {
    Verbose = 0,
    Information = 1,
    Warning = 2,
    Error = 3,
    Critical = 4
}

export function verboseinfo(msg: any, properties: any = null) {
    if (!flags.verboseLogging) return;
    const prefixMsg = `${logPrefix}${msg}`;
    appInsightsTrackTrace(prefixMsg, SeverityLevel.Information, properties);
    if (arguments.length > 1)
        console.info(`${prefixMsg}\n`, properties);
    else
        console.info(prefixMsg);
}

export function info(msg: any, properties: any = null) {
    const prefixMsg = `${logPrefix}${msg}`;
    appInsightsTrackTrace(prefixMsg, SeverityLevel.Information, properties);
    if (arguments.length > 1)
        console.info(`${prefixMsg}\n`, properties);
    else
        console.info(prefixMsg);
}

export function warn(msg: any, properties: any = null) {
    const prefixMsg = `${logPrefix}${msg}`;
    appInsightsTrackTrace(prefixMsg, SeverityLevel.Warning, properties);
    if (arguments.length > 1)
        console.warn(`${prefixMsg}\n`, properties);
    else
        console.warn(prefixMsg);
}

export function error(msg: any, properties: any = null) {
    const prefixMsg = `${logPrefix}${msg}`;
    appInsightsTrackTrace(prefixMsg, SeverityLevel.Error, properties);
    if (arguments.length > 1)
        console.error(`${prefixMsg}\n`, properties);
    else
        console.error(prefixMsg);
}

export function unavailable() {
    let msg = "PayCentral will not be available.";
    appInsightsTrackException(msg);
    warn(msg);
}

function appInsightsTrackTrace(msg: any, severity: SeverityLevel, properties: any = null) {
    if (!isAppInsightsAvailable()) return;

    try {
        appInsights.trackTrace({ message: msg, severityLevel: severity, properties: properties });
    }
    catch (e) {
        console.warn("Error logging to AppInsights with TrackTrace", e);
    }
}

function appInsightsTrackException(msg: any, properties: any = null) {
    if (!isAppInsightsAvailable()) return;

    try {
        let error = new Error(msg);
        appInsights.trackException({ exception: error, properties: properties });
    }
    catch (e) {
        console.warn("Error logging to AppInsights with TrackException", e);
    }
}

function isAppInsightsAvailable(): boolean {
    return typeof (appInsights) !== "undefined" && appInsights;
}

export function appInsightsOperationId(): string {
    if (!isAppInsightsAvailable()) return null;
    return appInsights.context?.telemetryTrace?.traceID;
}