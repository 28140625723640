"use strict";

let initialized = false;
let listenerAdded = false;
let subscribers = [];

export function initialize() {
    subscribers = [];
    if (!listenerAdded) {
        window.addEventListener("message", notify, false);
        listenerAdded = true;
    }
    initialized = true;
}

export function subscribe(subscriber) {
    if (!initialized) return;
    subscribers.push(subscriber);
}

export function unsubscribe(subscriber) {
    if (!initialized) return;
    let i = subscribers.findIndex((x) => x === subscriber);
    while (i >= 0) {
        subscribers.splice(i, 1);
        i = subscribers.findIndex((x) => x === subscriber);
    }
}

function notify(event) {
    if (!initialized) return;
    for (let subscriber of subscribers) {
        let handled = false;
        try {
            if (typeof subscriber["tryHandleMessage"] === "function")
                handled = subscriber["tryHandleMessage"](event);
        } catch (_) { }
        if (handled) {
            if (typeof event?.stopImmediatePropagation === "function")
                event.stopImmediatePropagation();
            return;
        }
    }
}

