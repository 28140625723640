"use strict";

import * as utilities from "paycentral.sdk.utilities";

export function validatePaymentMethod(method: PayCentral.PaymentMethod): string[] {

    const errors: string[] = [];

    if (!utilities.isObject(method))
        return ["Expected method to be an object."];

    if (typeof method.gateway === "undefined")
        errors.push("Expected a method.gateway property.");
    else {
        if (!utilities.isObject(method.gateway))
            errors.push("Expected method.gateway to be an object.");
        else {
            if (typeof method.gateway.id === "undefined")
                errors.push("Expected a method.gateway.id property.");
            else {
                if (!(typeof method.gateway.id === "string" && method.gateway.id))
                    errors.push("Expected method.gateway.id to be a non-empty string.");
            }
            if (typeof method.gateway.provider === "undefined")
                errors.push("Expected a method.gateway.provider property.");
            else {
                if (!(typeof method.gateway.provider === "string" && method.gateway.provider))
                    errors.push("Expected method.gateway.provider to be a non-empty string.");
            }
            if (!utilities.isUndefinedOrType(method.gateway.options, "object"))
                errors.push("Expected method.gateway.options be an object or undefined.");
            else {
                if (method.gateway.options) {
                    if (!utilities.isUndefinedOrNullOrType(method.gateway.options.iatsAgentCode, "string"))
                        errors.push("Expected method.gateway.options.iatsAgentCode to be a string or undefined.");
                    if (!utilities.isUndefinedOrType(method.gateway.options.use3ds, "boolean"))
                        errors.push("Expected method.gateway.options.use3ds to be a boolean or undefined.");
                }
            }
        }
    }

    // method.paymentType
    if (!(typeof method.paymentType === "string" && method.paymentType))
        errors.push("Expected method.paymentType to be a non-empty string.");
    else {
        switch (method.paymentType) {
            case "CreditCard":
            case "DirectDebit":
                break;
            default:
                errors.push("Unexpected value in method.paymentType.");
        }
    }

    // method.region
    if (!utilities.isUndefinedOrNullOrType(method.region, "string"))
        errors.push("Expected method.region to be a string or undefined.");
    else {
        if (method.paymentType === "DirectDebit" && !method.region)
            errors.push("Expected a method.region when method.paymentType is 'DirectDebit'.");
    }

    // method.capture
    // optional, so don't validate it if it's not there or null
    if (!(typeof method.capture === "undefined" || method.capture === null)) {
        if (!(typeof method.capture === "string" && method.capture))
            errors.push("Expected method.capture to be a non-empty string.");
        switch (method.capture) {
            case "Immediate":
            case "Delayed":
                break;
            default:
                errors.push("Unexpected value in method.capture.");
        }
    }

    return errors;

}

export function validatePaymentRequestOptions(options: PayCentral.PaymentRequestOptions, method: PayCentral.PaymentMethod): string[] {

    if (!utilities.isObject(options))
        return ["Expected options to be an object."];

    const errors: string[] = [
        ...validatePaymentRequestOptionsAmount(options),
        ...validatePaymentRequestOptionsBillingDetails(options),
        ...validatePaymentRequestOptionsCard(options),
        ...validatePaymentRequestOptionsAccount(options, method)
    ];

    if (!utilities.isOneOf(typeof options.recurring, "boolean", "undefined"))
        errors.push("Expected options.recurring to be a boolean or undefined.");

    return errors;

}

function validatePaymentRequestOptionsAmount(options: PayCentral.PaymentRequestOptions): string[] {

    const errors: string[] = [];

    if (typeof options.amount === "undefined") {
        errors.push("Expected an options.amount property.");
        return errors;
    }

    if (!utilities.isObject(options.amount)) {
        errors.push("Expected options.amount to be an object.");
        return errors;
    }

    if (typeof options.amount.amount === "undefined")
        errors.push("Expected an options.amount.amount property.");
    else {
        if (typeof options.amount.amount !== "number") {
            errors.push("Expected options.amount.amount to be a number.");
        }
    }

    if (typeof options.amount.currency === "undefined")
        errors.push("Expected an options.amount.currency property.");
    else {
        if (!(typeof options.amount.currency === "string" && options.amount.currency)) {
            errors.push("Expected options.amount.currency to be a non-empty string.");
        }
    }

    return errors;

}

function validatePaymentRequestOptionsBillingDetails(options: PayCentral.PaymentRequestOptions): string[] {

    if (typeof options.billingDetails === "undefined" || options.billingDetails === null)
        return [];

    return [
        ...validatePaymentRequestOptionsBillingDetailsName(options),
        ...validatePaymentRequestOptionsBillingDetailsAddress(options)
    ];

}

function validatePaymentRequestOptionsBillingDetailsName(options: PayCentral.PaymentRequestOptions): string[] {

    const errors: string[] = [];

    if (typeof options.billingDetails.name === "undefined" ||
        options.billingDetails.name === null)
        return errors;

    if (!utilities.isObject(options.billingDetails.name)) {
        errors.push("Expected options.billingDetails.name to be an object.");
        return errors;
    }

    if (!utilities.isUndefinedOrNullOrType(options.billingDetails.name.firstName, "string"))
        errors.push(
            "Expected options.billingDetails.name.firstName to be a string or undefined.");

    if (!utilities.isUndefinedOrNullOrType(options.billingDetails.name.lastName, "string"))
        errors.push(
            "Expected options.billingDetails.name.lastName to be a string or undefined.");

    return errors;

}

function validatePaymentRequestOptionsBillingDetailsAddress(options: PayCentral.PaymentRequestOptions): string[] {

    const errors: string[] = [];

    if (typeof options.billingDetails.address === "undefined" ||
        options.billingDetails.address === null)
        return errors;

    if (!utilities.isObject(options.billingDetails.address)) {
        errors.push("Expected options.billingDetails.address to be an object.");
        return errors;
    }

    if (typeof options.billingDetails.address.addressLines !== "undefined" &&
        options.billingDetails.address.addressLines !== null) {
        if (Array.isArray(options.billingDetails.address.addressLines)) {
            if (options.billingDetails.address.addressLines.some((x) => typeof x !== "string"))
                errors.push(
                    "Expected every element of options.billingDetails.address.addressLines to be a string.");
        } else {
            errors.push(
                "Expected options.billingDetails.address.addressLines to be an Array or undefined.");
        }
    }

    if (!utilities.isUndefinedOrNullOrType(options.billingDetails.address.cityName, "string"))
        errors.push(
            "Expected options.billingDetails.address.cityName to be a string or undefined.");
    if (!utilities.isUndefinedOrNullOrType(options.billingDetails.address.countryCode, "string"))
        errors.push(
            "Expected options.billingDetails.address.countryCode to be a string or undefined.");
    if (!utilities.isUndefinedOrNullOrType(options.billingDetails.address.countryName, "string"))
        errors.push(
            "Expected options.billingDetails.address.countryName to be a string or undefined.");
    if (!utilities.isUndefinedOrNullOrType(options.billingDetails.address.countrySubEntityCode, "string"))
        errors.push(
            "Expected options.billingDetails.address.countrySubEntityCode to be a string or undefined.");
    if (!utilities.isUndefinedOrNullOrType(options.billingDetails.address.countrySubEntityName, "string"))
        errors.push(
            "Expected options.billingDetails.address.countrySubEntityName to be a string or undefined.");
    if (!utilities.isUndefinedOrNullOrType(options.billingDetails.address.postalCode, "string"))
        errors.push(
            "Expected options.billingDetails.address.postalCode to be a string or undefined.");

    return errors;

}

function validatePaymentRequestOptionsCard(options: PayCentral.PaymentRequestOptions): string[] {

    const errors: string[] = [];

    if (typeof options.card === "undefined" || options.card === null) return errors;

    if (!utilities.isObject(options.card)) {
        errors.push("Expected options.card to be an object.");
        return errors;
    }

    if (!utilities.isUndefinedOrNullOrType(options.card.cardholderName, "string"))
        errors.push(
            "Expected options.card.cardholderName to be a string or undefined.");

    return errors;

}

function validatePaymentRequestOptionsAccount(options: PayCentral.PaymentRequestOptions, method: PayCentral.PaymentMethod): string[] {

    const errors: string[] = [];

    if (typeof options.account === "undefined" || options.account === null)
    {
        // some required properties when DirectDebit
        if (method.paymentType === "DirectDebit") {
            errors.push("Expected options.account to be an object.");
        }
        return errors;
    }

    if (!utilities.isObject(options.account)) {
        errors.push("Expected options.account to be an object.");
        return errors;
    }

    if (!utilities.isUndefinedOrNullOrType(options.account.accountType, "string"))
        errors.push(
            "Expected options.account.accountType to be a string or undefined.");
    else {
        if (method.paymentType === "DirectDebit" && !options.account.accountType) {
            errors.push("Expected an options.account.accountType when method.paymentType is 'DirectDebit'.");
        }
    }

    return errors;

}

