"use strict";

import * as validator from "paycentral.sdk.paymentrequest.validator";
import * as utilities from "paycentral.sdk.utilities";
import * as errorHandler from "paycentral.sdk.error";
import * as paymentControl from "paycentral.sdk.paymentcontrol";
import * as logger from "paycentral.sdk.logger";

let asyncOpId = 0;

export function create(paycentralInstance: PayCentral.Internal.PayCentralInstance,
    method: PayCentral.PaymentMethod,
    options: PayCentral.PaymentRequestOptions): PayCentral.Internal.PaymentRequest {

    return (new PaymentRequest(paycentralInstance, method, options)).api();
}

class PaymentRequest {

    destroyed = false;
    complete = false;
    paymentControl: PayCentral.Internal.PaymentControl;
    paymentControlState: "none" | "loading" | "loaded" = "none";
    asyncOps: string[] = [];
    paycentralInstance: PayCentral.Internal.PayCentralInstance;
    paymentMethod: PayCentral.PaymentMethod;
    paymentRequestOptions: PayCentral.PaymentRequestOptions;


    constructor(paycentralInstance: PayCentral.Internal.PayCentralInstance,
        method: PayCentral.PaymentMethod,
        options: PayCentral.PaymentRequestOptions) {

        const validationErrors = [...validator.validatePaymentMethod(method), ...validator.validatePaymentRequestOptions(options, method)];
        if (validationErrors?.length) throw errorHandler.toPayCentralError(validationErrors);

        this.paycentralInstance = paycentralInstance;
        this.paymentMethod = utilities.deepClone(method);
        this.paymentRequestOptions = utilities.deepClone(options);

        // default to Immediate capture if it was not supplied
        if (!this.paymentMethod.capture) this.paymentMethod.capture = "Immediate";

    }

    /*
     * Return an api to this instance
     */
    api(): PayCentral.Internal.PaymentRequest {
        const me = this;
        return {
            destroy: () => me.destroy(),
            get destroyed() { return me.destroyed },
            get complete() { return me.complete },

            // the public property contains all the properties exposed to an sdk consumer
            public: {
                show: (domQuerySelector: string, options?: PayCentral.ShowOptions) => me.show(domQuerySelector, options),
                update: (options: PayCentral.PaymentRequestOptions) => me.update(options),
                submit: () => me.submit(),
                destroy: () => me.destroy()
            }
        };
    }

    // START API -- functions exposed via the API

    async show(container: string | HTMLElement, options?: PayCentral.ShowOptions):
        Promise<void> {

        logger.verboseinfo("paycentral.sdk.paymentrequest.show() called.", this.getLogProperties());

        if (typeof container === "undefined" || container === null)
            throw errorHandler.toPayCentralError("Expected container to be supplied.");
        if (!(container instanceof HTMLElement || (typeof container === "string" && container)))
            throw errorHandler.toPayCentralError("Expected container to be either an Element or a DOM query selector string.");

        if (!utilities.isOneOf(typeof options, "object", "undefined"))
            throw errorHandler.toPayCentralError("Expected options to be an object.");
        else if (options) {
            if (!utilities.isOneOf(typeof options.timeout, "undefined", "number")) {
                throw errorHandler.toPayCentralError("Expected options.timeout to be a number.");
            }
            if (!utilities.isOneOf(typeof options.customCss, "undefined", "boolean")) {
                throw errorHandler.toPayCentralError("Expected options.customCss to be a boolean.");
            }
        }

        this.throwIfDestroyed();
        this.throwIfComplete();
        if (this.paymentControlState !== "none")
            throw errorHandler.toPayCentralError("The payment control is either already loaded or is in the process of loading.");
        this.throwIfAnyUnfulfilledPromise();

        let div: HTMLElement;
        if (typeof container === "string") {
            let candidate: HTMLElement;
            try {
                candidate = document.querySelector(container);
            } catch (e) {
                throw errorHandler.toPayCentralError("Unable to evaluate the DOM query selector supplied in container.");
            }
            if (!candidate)
                throw errorHandler.toPayCentralError(
                    "The DOM query selector supplied in container does not identify an element in the DOM.");
            div = candidate;
        } else
            div = container;
        if (div.tagName !== "DIV") {
            throw errorHandler.toPayCentralError("The supplied container must identify a DIV element the DOM.");
        }

        const opCode = this.pushAsyncOp("show");
        try {
            await this.loadPaymentControl(div, options);
        } catch (e) {
            throw errorHandler.toPayCentralError(e);
        } finally {
            this.popAsyncOpCode(opCode);
        }

    }

    async update(options: PayCentral.PaymentRequestOptions): Promise<void> {

        logger.verboseinfo("paycentral.sdk.paymentrequest.update() called.", this.getLogProperties());

        this.throwIfDestroyed();
        this.throwIfComplete();
        // no need to check if the control is displayed, we support updating a PaymentRequest before a UI is instantiated
        this.throwIfAnyUnfulfilledPromise();

        const validationErrors = validator.validatePaymentRequestOptions(options, this.paymentMethod);
        if (validationErrors?.length) throw errorHandler.toPayCentralError(validationErrors);

        const opCode = this.pushAsyncOp("update");
        try {

            this.paymentRequestOptions = utilities.deepClone(options);

            if (this.paymentControlState === "loaded") {
                await this.paymentControl.update(this.paymentRequestOptions);
            }

            return;

        } catch (e) {
            throw errorHandler.toPayCentralError(e);
        } finally {
            this.popAsyncOpCode(opCode);
        }

    }

    async submit(): Promise<PayCentral.PaymentResult> {

        logger.verboseinfo("paycentral.sdk.paymentrequest.submit() called.", this.getLogProperties());

        this.throwIfDestroyed();
        this.throwIfComplete();
        if (this.paymentControlState !== "loaded") throw errorHandler.toPayCentralError("The payment control has not been displayed.");
        this.throwIfAnyUnfulfilledPromise();

        const opCode = this.pushAsyncOp("submit");

        try {
            const result = await this.paymentControl.submit();
            logger.verboseinfo("paycentral.sdk.paymentrequest.submit() returned a result.", { ...this.getLogProperties(), ...{ result: result } });
            this.complete = this.paymentControl.complete;
            return result;
        } catch (e) {
            logger.verboseinfo("paycentral.sdk.paymentrequest.submit() failed with an error.", { ...this.getLogProperties(), error: e });
            throw errorHandler.toPayCentralError(e);
        } finally {
            this.popAsyncOpCode(opCode);
        }

    }

    destroy() {

        logger.verboseinfo("paycentral.sdk.paymentrequest.destroy() called.", this.getLogProperties());

        if (this.destroyed) return;

        // disallow a destroy when certain async ops are in progress
        // this is typically because they cannot be aborted in a useful way
        if (this.executingAsyncOp("submit")) {
            throw errorHandler.toPayCentralError(
                "A payment request cannot be destroyed whilst a submit() is in progress.");
        }
        if (this.executingAsyncOp("update")) {
            throw errorHandler.toPayCentralError(
                "A payment request cannot be destroyed whilst an update() is in progress.");
        }

        if (this.paymentControl) this.paymentControl.destroy();

        this.destroyed = true;
    }

    // END API

    pushAsyncOp(op: string): string {
        const opCode = `${op}_${asyncOpId++}`;
        this.asyncOps.push(opCode);
        return opCode;
    }

    popAsyncOpCode(opCode) {
        let i: number;
        while ((i = this.asyncOps.indexOf(opCode)) !== -1)
            this.asyncOps.splice(i, 1);
    }

    executingAsyncOp(op) {
        const regex = new RegExp(`^${op}_\\d+$`);
        return this.asyncOps.some((x) => regex.test(x));
    }

    throwIfDestroyed() {
        if (this.destroyed)
            throw errorHandler.toPayCentralError("This payment request has been destroyed and can no longer be acted on.");
    }

    throwIfComplete() {
        if (this.complete) throw errorHandler.toPayCentralError("This payment request has completed and can no longer be acted on.");
    }

    throwIfAnyUnfulfilledPromise() {
        if (this.asyncOps?.length > 0)
            throw errorHandler.toPayCentralError(
                "This payment request has unfulfilled promises. Wait for these promises to fulfill before attempting this operation.");
    }

    async loadPaymentControl(container: HTMLElement, options?: PayCentral.ShowOptions): Promise<void> {

        let control: PayCentral.Internal.PaymentControl;

        try {

            this.paymentControlState = "loading";

            this.paymentControl = control = paymentControl.create(this.paycentralInstance, this.paymentMethod, this.paymentRequestOptions);
            await control.load(container, options);

            this.paymentControlState = "loaded";

        } catch (e) {
            this.paymentControlState = "none";
            this.paymentControl = null;
            if (control) control.destroy();
            throw errorHandler.toPayCentralError(e);
        }

    }

    getLogProperties() {
        return { token: this.paycentralInstance?.token };
    }

}
