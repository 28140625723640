"use strict";

export function isOneOf<T>(subject: T, ...matches: T[]): boolean {
    return matches.indexOf(subject) !== -1;
}

export function deepClone<T>(o: T): T {
    try {
        if ("structuredClone" in window && typeof window["structuredClone"] === "function") {
            return window["structuredClone"](o);
        }
    } catch (e) { }
    try {
        return JSON.parse(JSON.stringify(o));
    } catch (e) { }
    // it won't clone, unlikely
    return o;
}

export function stringify(data: any): string {
    if (typeof data === "undefined") return "undefined";
    if (typeof data === "function") return "function";
    if (data === null) return "null";
    if (data instanceof Error) return data.toString();
    if (data instanceof Date) return data.toString();
    try {
        if (typeof data === "object") return JSON.stringify(data);
    } catch (_) { }
    return data.toString();
}

export function isUndefinedOrNullOrType(reference: any, ...types: string[]): boolean {
    if (typeof reference === "undefined" || reference === null) return true;
    if (typeof reference === "object" && types.indexOf("object") !== -1) return isObject(reference);
    return isOneOf(typeof reference, ...types);
}

export function isUndefinedOrType(reference: any, ...types: string[]): boolean {
    if (typeof reference === "undefined") return true;
    if (typeof reference === "object" && types.indexOf("object") !== -1) return isObject(reference);
    return isOneOf(typeof reference, ...types);
}

/*
 * Indicates if a supplied value is an object.
 * Ignores: Array, Date(object), null
 */
export function isObject(reference: any): boolean {
    return typeof reference === "object"
        && reference !== null
        && !Array.isArray(reference)
        && !(reference instanceof Date);
}

export function getStack(maxLevel?: number): string {

    if (!maxLevel || maxLevel < 0)
        maxLevel = 0;
    else
        maxLevel = Math.floor(maxLevel);

    try {
        const e = new Error();
        // Chrome limits the stack to 10 levels by default
        // This can be overriden with Error.stackTraceLimit, but that is not compatible with all browsers.
        // MDN does not recommend using Error.stack at all since it is not a standard feature
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/stack
        const stack = e.stack || e.stacktrace;
        if (typeof stack !== "string") return null;
        let stackArray = stack.split("\n");
        if (stackArray[0].startsWith("Error"))
            stackArray = stackArray.slice(2);
        else
            stackArray = stackArray.slice(1);
        if (maxLevel > 0)
            return stackArray.slice(0, maxLevel + 1).join("\n");
        else
            return stackArray.join("\n");
    }
    catch (_) {
        return null;
    }

}
