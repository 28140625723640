export function get<T = any>(endpoint: string, queryParams: { [key: string]: string } = {}): Promise<T> {
    return makeRequest<T>("GET", createRequestUrl(endpoint, queryParams));
}

export function post<T = any>(endpoint: string, payload?: any): Promise<T> {
    return makeRequest<T>("POST", createRequestUrl(endpoint), payload);
}

async function makeRequest<T>(method: string, url: string, data?: any): Promise<T> {

    console.log(method, url, data);

    const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json"
    };

    try {
        const rawResponse = await fetch(url, {
            body: data ? JSON.stringify(data) : null,
            credentials: "omit",
            headers: typeof Headers !== "undefined" ? new Headers(headers) : headers,
            method: method,
        });

        if (!rawResponse.ok) {
            const text = await rawResponse.text();
            throw new Error(`(${rawResponse.status.toString()}) ${text}`);
        }

        return await rawResponse.json();
    } catch (e) {
        console.log(e);
        throw new Error(`Call to ${new URL(url).pathname} failed.`);
    }
}

/**
* Constructs an API request URL with the given endpoint and query parameters.
* A "token" query parameter is always included in the URL.
*
* @param endpoint - The API endpoint (e.g., '/payments', '/gateways').
* @param queryParams - An optional collection of query parameters as key-value pairs.
* @returns The constructed API request URL.
*/
export function createRequestUrl(endpoint: string, queryParams: { [key: string]: string } = {}): string {
    const url = new URL(endpoint, location.href);
    url.searchParams.set("token", `${new URLSearchParams(location.search).get("token")}`);
    for (const key in queryParams) {
        if (queryParams.hasOwnProperty(key)) {
            url.searchParams.set(key, queryParams[key].toString());
        }
    }
    return url.toString();
}